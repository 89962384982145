import React from 'react'
import Layout from '../components/Layout'
import * as styles  from '../styles/project-details.module.css'
import { graphql, Link } from 'gatsby'
import { Row, Col, Button}  from 'react-bootstrap'

import Seo from '../components/SEO'

export default function PHPSnippetDetails({data}) {
    const { html } = data.markdownRemark
    const { title, keywords, published, modified, description, type} = data.markdownRemark.frontmatter

      return (
        <Layout>
            <Seo
                title={title}
                description={description}
                keywords={keywords}
                published={published}
                modified={modified}
                type={type}
            />
              <Row>
                  <Col md={12}>
                  <div className={styles.details}>
                      <Link to="/howto/php/"><Button variant="outline-primary">Back to list</Button></Link>
                      <h1>{title}</h1>
                      <small>Last modified: {modified}</small>
                      <div className={styles.html} dangerouslySetInnerHTML={{ __html: html }} />
                  </div>
                  </Col>
              </Row>
          </Layout>
      )
}

export const query = graphql`
  query phpDetail($slug: String) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        keywords
        description
        published(formatString: "MMMM DD, YYYY")
        modified(formatString: "MMMM DD, YYYY")
        type
        featured
      }
    }
  }
`
